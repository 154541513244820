// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import 'ckeditor5/ckeditor5.css';
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900;9..40,1000&display=swap");
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
$mars-palette: (
  50: #e2e3ea,
  100: #b6bacd,
  200: #878eab,
  300: #5c658a,
  400: #3d4775,
  500: #1b2c61,
  600: #16265a,
  700: #0e1e50,
  800: #061644,
  900: #03002f,
  contrast: (),
);
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$mars-admin-primary: mat.define-palette($mars-palette, 900);
$mars-admin-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
// The warn palette is optional (defaults to red).
$mars-admin-warn: mat.define-palette(mat.$red-palette);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$mars-admin-theme: mat.define-light-theme(
  (
    color: (
      primary: $mars-admin-primary,
      accent: $mars-admin-accent,
      warn: $mars-admin-warn,
    ),
  )
);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($mars-admin-theme);
/* You can add global styles to this file, and also import other style files */

:root {
  /*******************************/
  /*******************************/
  // HEADING TAG VALUES

  --h1_size: 48px;
  --h1_lineheight: 58px;
  --h1_fontweight: 700;
  --h1_size_md: 40px;
  --h1_lineheight_md: 48px;
  --h1_fontweight_md: 700;

  --h2_size: 40px;
  --h2_lineheight: 48px;
  --h2_fontweight: 600;
  --h2_size_md: 32px;
  --h2_lineheight_md: 40px;
  --h2_fontweight_md: 600;

  --h3_size: 32px;
  --h3_lineheight: 40px;
  --h3_fontweight: 600;
  --h3_size_md: 24px;
  --h3_lineheight_md: 32px;
  --h3_fontweight_md: 600;

  --h4_size: 24px;
  --h4_lineheight: 32px;
  --h4_fontweight: 600;
  --h4_size_md: 18px;
  --h4_lineheight_md: 24px;
  --h4_fontweight_md: 600;

  --h5_size: 20px;
  --h5_lineheight: 28px;
  --h5_fontweight: 600;
  --h5_size_md: 16px;
  --h5_lineheight_md: 24px;
  --h5_fontweight_md: 600;

  --h6_size: 16px;
  --h6_lineheight: 24px;
  --h6_fontweight: 600;
  --h6_size_md: 14px;
  --h6_lineheight_md: 20px;
  --h6_fontweight_md: 600;
  /*******************************/
  // TITLE TEXT VALUES

  --t1_size: 20px;
  --t1_lineheight: 28px;
  --t1_fontweight: 400;
  --t1_size_md: 18px;
  --t1_lineheight_md: 24px;
  --t1_fontweight_md: 400;

  --t2_size: 16px;
  --t2_lineheight: 24px;
  --t2_fontweight: 300;
  --t2_size_md: 14px;
  --t2_lineheight_md: 20px;
  --t2_fontweight_md: 300;

  /*******************************/
  // PARAGRAPGH TEXT VALUES

  --p1_size: 16px;
  --p1_lineheight: 28px;
  --p1_fontweight: 400;
  --p1_size_md: 14px;
  --p1_lineheight_md: 24px;
  --p1_fontweight_md: 400;

  --p2_size: 14px;
  --p2_lineheight: 24px;
  --p2_fontweight: 400;
  --p2_size_md: 12px;
  --p2_lineheight_md: 20px;
  --p2_fontweight_md: 400;

  /*******************************/
  // LABEL TEXT VALUES

  --l1_size: 14px;
  --l1_lineheight: 20px;
  --l1_fontweight: 300;
  --l1_size_md: 12px;
  --l1_lineheight_md: 16px;
  --l1_fontweight_md: 300;

  --l2_size: 12px;
  --l2_lineheight: 16px;
  --l2_fontweight: 400;
  --l2_size_md: 10px;
  --l2_lineheight_md: 12px;
  --l2_fontweight_md: 400;

  --l3_size: 12px;
  --l3_lineheight: 16px;
  --l3_fontweight: 500;
  --l3_size_md: 10px;
  --l3_lineheight_md: 12px;
  --l3_fontweight_md: 500;

  --l4_size: 10px;
  --l4_lineheight: 16px;
  --l4_fontweight: 400;
  --l4_size_md: 8px;
  --l4_lineheight_md: 12px;
  --l4_fontweight_md: 400;

  /*******************************/
  // BUTTON TEXT VALUES

  --b1_size: 16px;
  --b1_lineheight: 24px;
  --b1_fontweight: 600;
  --b1_size_md: 16px;
  --b1_lineheight_md: 24px;
  --b1_fontweight_md: 600;

  --b2_size: 14px;
  --b2_lineheight: 20px;
  --b2_fontweight: 600;
  --b2_size_md: 14px;
  --b2_lineheight_md: 20px;
  --b2_fontweight_md: 600;

  --b3_size: 12px;
  --b3_lineheight: 18px;
  --b3_fontweight: 600;
  --b3_size_md: 12px;
  --b3_lineheight_md: 16px;
  --b3_fontweight_md: 600;

  //color
  --primary50: #e9e8fd;
  --primary500: #2716e9;
  --primary700: #1c10a5;
  --secondary: #FCB619;
  --surface10: #FCFCFD;
  --surface20: #F5F5F5;
  --surface50: #ECECED;
  --surface100: #C3C4C7;
  --surface200: #A6A8AC;
  --surface300: #7D8086;
  --surface500: #3D414B;
  --surface700: #2B2E35;
  --surface800: #1E2025;
  --surface900: #121317;
  --success: #0b8c20;
  --danger: #c3202f;
  --warning: #ff7a03;
  --color_4: #fafafa;
  --color_3: #a6a8ac;
  --border2: #c3c4c7;

  //button variable
  --btn-bg-color: transparent;
  --btn-font-color: inherit;
  --btn-font-size: 1rem;
  --btn-line-height: 1.25rem;
  --btn-font-weight: 600;
  --btn-border-color: transparent;
  --btn-padding: 0.8125rem 2rem;
  --btn-border-radius: 0.5rem;
  --btn-border-width: 1px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-h1;
  }
  h2 {
    @apply text-h2;
  }
  h3 {
    @apply text-h3;
  }
  h4 {
    @apply text-h4;
  }
  h5 {
    @apply text-h5;
  }
  h6 {
    @apply text-h6;
  }

  @screen md {
    h1 {
      @apply text-h1-md;
    }
    h2 {
      @apply text-h2-md;
    }
    h3 {
      @apply text-h3-md;
    }
    h4 {
      @apply text-h4-md;
    }
    h5 {
      @apply text-h5-md;
    }
    h6 {
      @apply text-h6-md;
    }
  }
  .wrap-control input,
  .wrap-control textarea {
    outline: none;
  }
  .wrap-control:has(input:enabled:focus, input:not(:placeholder-shown), textarea:focus, textarea:not(:placeholder-shown)) {
    border-color: var(--primary500);
  }
  .wrap-control:has(input.is-invalid, textarea.is-invalid) {
    border-color: var(--danger);
  }
  .wrap-control:has(*:disabled, *.disabled) {
    background: var(--surface50);
  }

  .custom-dropdown {
    .select-dropdown {
      transition: 0.5s ease;
      transform: scaleY(0);
      opacity: 0;
      visibility: hidden;
    }
    &.active {
      border-color: var(--primary500);
      .select-dropdown {
        opacity: 1;
        visibility: visible;
        transform: scaleY(1);
      }
    }
    &:has(.is-invalid) {
      border-color: var(--danger) !important;
    }
  }
}

// html, body { height: 100%; }
body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
}
input[type="radio"].custom-radio{
  @apply w-4 h-4 accent-primary-500;
}
input[type="text"].custom-input, input[type="number"].custom-input{
  @apply outline-none p-3 border rounded-lg border-b2 placeholder:text-[#717075];
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -moz-appearance: none;
  appearance: none;
}

// input[type="checkbox"] {
//   width: 20px;
//   height: 20px;

//   &:checked {
//     @apply accent-primary-500;
//   }
// }
.scroller::-webkit-scrollbar {
  @apply bg-surface-100 min-h-[2px] rounded-full w-1.5;
}
.scroller::-webkit-scrollbar-thumb {
  @apply bg-tertiary rounded-full;
  background-clip: padding-box;
}
.noScroll::-webkit-scrollbar {
  @apply min-h-0 w-0;
}
.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: inherit;
}

.cdk-overlay-backdrop {
  background-color: rgba(0, 0, 0, 0.7);
}

.customToolTip {
  .mdc-tooltip__surface {
    border-radius: 5px !important;
    border: 1px solid var(--mobile-border-b-1, #e7e7e7);
    background-color: #f7f7f7 !important;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    color: #343257 !important;
    font-size: 10px !important;
  }
}

.custom-radio{
  @apply border-primary-500  bg-surface-10 text-primary-500 border-2 rounded-full min-h-[20px] min-w-[20px] appearance-none;
}

.btn {
  outline: none;
  background: var(--btn-bg-color);
  color: var(--btn-font-color);
  font-size: var(--btn-font-size);
  line-height: var(--btn-line-height);
  font-weight: var(--btn-font-weight);
  padding: var(--btn-padding);
  border-width: var(--btn-border-width);
  border-color: var(--btn-border-color);
  border-radius: var(--btn-border-radius);
  border-style: solid;
  text-align: center;
  &.link {
    padding: 0px !important;
  }

  &-small {
    --btn-font-size: 0.875rem;
    --btn-line-height: 1rem;
    --btn-padding: 0.4375rem 1rem;
  }
  &-large {
    --btn-font-size: 1.25rem;
    --btn-line-height: 1.75rem;
    --btn-padding: 0.875rem 2.5rem;
  }
  &-primary {
    &:not(.fill) {
      --btn-font-color: var(--primary500);
      &:not(.outline):hover {
        --btn-font-color: var(--primary700);
        text-decoration: underline;
      }
    }
    &:not(.link) {
      --btn-border-color: var(--primary500);
      &:hover {
        --btn-bg-color: var(--primary50);
        --btn-font-color: var(--primary700);
      }
      &:disabled,
      &.disabled {
        --btn-border-color: var(--border2);
        --btn-bg-color: var(--surface50);
        --btn-font-color: var(--color_3);
        cursor: not-allowed;
      }
    }
    &:not(.outline, .link) {
      --btn-bg-color: var(--primary500);
      --btn-font-color: var(--color_4);
      &:hover {
        --btn-bg-color: var(--primary700);
        --btn-font-color: var(--color_4);
      }
      &:disabled,
      &.disabled {
        --btn-border-color: var(--surface50);
        --btn-bg-color: var(--surface50);
        --btn-font-color: var(--color_3);
        cursor: not-allowed;
      }
    }
  }
  &-secondary {
    &:not(.fill) {
      --btn-font-color: var(--surface100);
    }
    &:not(.link) {
      --btn-border-color: var(--surface100);
    }
    &:not(.outline, .link) {
      --btn-bg-color: var(--surface100);
      --btn-font-color: var(--color_4);
    }
  }
  &-success {
    &:not(.fill) {
      --btn-font-color: var(--success);
    }
    &:not(.link) {
      --btn-border-color: var(--success);
    }
    &:not(.outline, .link) {
      --btn-bg-color: var(--success);
      --btn-font-color: var(--color_4);
    }
  }
  &-danger {
    &:not(.fill) {
      --btn-font-color: var(--danger);
    }
    &:not(.link) {
      --btn-border-color: var(--danger);
    }
    &:not(.outline, .link) {
      --btn-bg-color: var(--danger);
      --btn-font-color: var(--color_4);
    }
  }
  &-warning {
    &:not(.fill) {
      --btn-font-color: var(--warning);
    }
    &:not(.link) {
      --btn-border-color: var(--warning);
    }
    &:not(.outline, .link) {
      --btn-bg-color: var(--warning);
      --btn-font-color: var(--color_4);
    }
  }
}

//ck-editor
.ck-content {
  font-family: "DM Sans", sans-serif;
  line-height: 1.6;
  word-break: break-word;
}

.ck-content h3.category {
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #555;
  letter-spacing: 10px;
  margin: 0;
  padding: 0;
}

.ck-content h2.document-title {
  font-family: "DM Sans", sans-serif;
  font-size: 50px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  border: 0;
}

.ck-content h3.document-subtitle {
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  color: #555;
  margin: 0 0 1em;
  font-weight: bold;
  padding: 0;
}

.ck-content p.info-box {
  --background-size: 30px;
  --background-color: #e91e63;
  padding: 1.2em 2em;
  border: 1px solid var(--background-color);
  background: linear-gradient(135deg,
      var(--background-color) 0%,
      var(--background-color) var(--background-size),
      transparent var(--background-size)),
    linear-gradient(135deg,
      transparent calc(100% - var(--background-size)),
      var(--background-color) calc(100% - var(--background-size)),
      var(--background-color));
  border-radius: 10px;
  margin: 1.5em 2em;
  box-shadow: 5px 5px 0 #ffe6ef;
}

.ck-content blockquote.side-quote {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  float: right;
  width: 35%;
  position: relative;
  border: 0;
  overflow: visible;
  z-index: 1;
  margin-left: 1em;
}

.ck-content blockquote.side-quote::before {
  content: '“';
  position: absolute;
  top: -37px;
  left: -10px;
  display: block;
  font-size: 200px;
  color: #e7e7e7;
  z-index: -1;
  line-height: 1;
}

.ck-content blockquote.side-quote p {
  font-size: 2em;
  line-height: 1;
}

.ck-content blockquote.side-quote p:last-child:not(:first-child) {
  font-size: 1.3em;
  text-align: right;
  color: #555;
}

.ck-content span.marker {
  background: yellow;
}

.ck-content span.spoiler {
  background: #000;
  color: #000;
}

.ck-content span.spoiler:hover {
  background: #000;
  color: #fff;
}

.ck-content pre.fancy-code {
  border: 0;
  margin-left: 2em;
  margin-right: 2em;
  border-radius: 10px;
}

.ck-content pre.fancy-code::before {
  content: '';
  display: block;
  height: 13px;
  background: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NCAxMyI+CiAgPGNpcmNsZSBjeD0iNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGMzZCNUMiLz4KICA8Y2lyY2xlIGN4PSIyNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGOUJFNEQiLz4KICA8Y2lyY2xlIGN4PSI0Ny41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiM1NkM0NTMiLz4KPC9zdmc+Cg==);
  margin-bottom: 8px;
  background-repeat: no-repeat;
}

.ck-content pre.fancy-code-dark {
  background: #272822;
  color: #fff;
  box-shadow: 5px 5px 0 #0000001f;
}

.ck-content pre.fancy-code-bright {
  background: #dddfe0;
  color: #000;
  box-shadow: 5px 5px 0 #b3b3b3;
}

.c-m-v-dialog .ck-content.ck-editor__editable {
  min-height: 136px;
  max-height: 280px;
}

.prose {
  --tw-prose-body: inherit;
  --tw-prose-headings: inherit;
  --tw-prose-lead: inherit;
  --tw-prose-links: inherit;
  --tw-prose-bold: inherit;
  --tw-prose-counters: inherit;
  --tw-prose-bullets: inherit;
  --tw-prose-hr: inherit;
  --tw-prose-quotes: inherit;
  --tw-prose-quote-borders: inherit;
  --tw-prose-captions: inherit;
  --tw-prose-kbd: inherit;
  --tw-prose-kbd-shadows: 17 24 39;
  --tw-prose-code: inherit;
  --tw-prose-pre-code: inherit;
  --tw-prose-pre-bg: inherit;
  --tw-prose-th-borders: inherit;
  --tw-prose-td-borders: inherit;
  --tw-prose-invert-body: inherit;
  --tw-prose-invert-headings: inherit;
  --tw-prose-invert-lead: inherit;
  --tw-prose-invert-links: inherit;
  --tw-prose-invert-bold: inherit;
  --tw-prose-invert-counters: inherit;
  --tw-prose-invert-bullets: inherit;
  --tw-prose-invert-hr: inherit;
  --tw-prose-invert-quotes: inherit;
  --tw-prose-invert-quote-borders: inherit;
  --tw-prose-invert-captions: inherit;
  --tw-prose-invert-kbd: inherit;
  --tw-prose-invert-kbd-shadows: 255 255 255;
  --tw-prose-invert-code: inherit;
  --tw-prose-invert-pre-code: inherit;
  --tw-prose-invert-pre-bg: inherit;
  --tw-prose-invert-th-borders: inherit;
  --tw-prose-invert-td-borders: inherit;
}